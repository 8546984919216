  'use strict'
  
  resizeFlg = {}
  resizeFlg.pc = true
  resizeFlg.sp = true
  firstView = ''
  
  new AOS.init()
  
  $menu = $('.js-menu')
  
  startPos = 0
  
  logo = new Freezeframe('#hoyos-sign', {
    trigger: false
  })
  
  $(window).on('scroll', ()->
    AOS.refresh()
  
    currentPos = $(this).scrollTop()
  
    if $(this).scrollTop() < $('.p-header').outerHeight()
      if currentPos < startPos
        $('.js-menu').removeClass('is-active')
        $('.js-menu__button').removeClass('is-active')
  
    startPos = currentPos
  )
  
  $(window).on('load', ()->
    # setTimeout(()->
    #   $('.js-opening').addClass('is-hide')
    # , 3000)
  
    $menu.find('img').each(
      ()->
        lazySizes.loader.unveil($(this).get(0))
    )
  
    titles = contents = []
  
    controller = new ScrollMagic.Controller()
  
    $('.c-title').each(
      (key)->
        $target = $(this)
  
        console.log($target)
  
        titles[key] = new ScrollMagic.Scene({
          triggerElement: '#'+$target.attr('id')
          duration: $(this).outerHeight()
        })
        # .addIndicators( {name: '#'+$target.attr('id')})
        .on('enter',
          (e)->
            console.log('enter')
        )
        .on('leave',
          (e)->
            console.log('leave')
        )
        .addTo(controller)
    )
  
    $('.js-animation').each(
      (key)->
        $target = $(this)
  
        config = {
          triggerElement: '#'+$target.attr('id')
        }
  
        if $target.attr('id') is 'hoyos'
          config.offset = $target.outerHeight() * -1
  
        contents[key] = new ScrollMagic.Scene(config)
        # .addIndicators( {name: '#'+$target.attr('id')})
        .on('enter',
          (e)->
            console.log('enter '+$target.attr('id'))
            if $target.find('.c-title').length > 0
              $target.addClass('is-animated')
  
            if $target.attr('id') is 'about'
              if not $target.hasClass('is-animated')
                $target.addClass('is-animated')
  
              setTimeout(
                ()->
                  $target.find('.js-slide').slick('slickPlay')
              , 4000)
  
            if $target.attr('id') is 'hoyos'
              logo.start()
  
            # if $target.attr('id') is 'value'
            #
            # if $target.attr('id') is 'clinic'
            #
            # if $target.attr('id') is 'faq'
        )
        .addTo(controller)
    )
  
    $('.p-header__pic.js-slide').slick({
      autoplay: true
      fade: true
      arrows: false
      draggable: false
      swipe: false
      mobileFirst: true
      waitForAnimate: false
      autoplaySpeed: 4000
      asNavFor: '.p-header__text.js-slide'
    })
  
    $($('.p-header__pic.js-slide .slick-slide').get(0)).addClass('is-animated')
  
    $('.p-header__pic.js-slide').on('beforeChange', (event, slick, currentSlide, nextSlide)->
      $($('.p-header__pic.js-slide .slick-slide').get(nextSlide)).addClass('is-animated')
    )
  
    $('.p-header__pic.js-slide').on('afterChange', (evenn, slick, currentSlide)->
      $($('.p-header__pic.js-slide .slick-slide').get(currentSlide - 1)).removeClass('is-animated')
    )
  
    $('.p-header__text.js-slide').slick({
      fade: true
      arrows: false
      draggable: false
      swipe: false
      mobileFirst: true
      waitForAnimate: false
      asNavFor: '.p-header__pic.js-slide'
    })
  
    # $($('.p-header__text.js-slide .slick-slide').get(0)).addClass('is-animated')
    #
    # $('.p-header__text.js-slide').on('beforeChange', (event, slick, currentSlide, nextSlide)->
    #   $($('.p-header__text.js-slide .slick-slide').get(nextSlide)).addClass('is-animated')
    # )
    #
    # $('.p-header__text.js-slide').on('afterChange', (evenn, slick, currentSlide)->
    #   $($('.p-header__text.js-slide .slick-slide').get(currentSlide - 1)).removeClass('is-animated')
    # )
  
    # $('.p-header__text.js-slide').on('setPosition',
    #   (s)->
    #     $('.p-header__text.js-slide').find('.slick-slide').removeClass('is-animated')
    #     $('[data-slick-index='+$('.p-header__text.js-slide').slick('slickCurrentSlide')+']').addClass('is-animated')
    # )
  
    # $('.p-header__text.js-slide .slick-slide').on('animationend', ()->
    #   $(this).removeClass('is-animation')
    # )
  
    $('.p-about__pic.js-slide').slick({
      mobileFirst: true
      dots: true
      asNavFor: '.p-about__text'
    })
  
    $('.p-about__text.js-slide').slick({
      mobileFirst: true
      arrows: false
      asNavFor: '.p-about__pic'
    })
  
    $('.p-about .js-slide').on('swipe', (event, slick, direction)->
      $('.p-about .js-slide').slick('slickPause')
    )
  
    $('.p-about .js-slide button').on('click', ()->
      console.log('click')
      $('.p-about .js-slide').slick('slickPause')
    )
  
    $('.p-doctors__slide.js-slide').slick({
      autoplay: true
      arrows: false
      draggable: false
      mobileFirst: true
      dots: true
    })
  
    $('.p-faq__list.js-slide').slick({
      arrows: false
      draggable: false
      mobileFirst: true
      centerMode: true
      centerPadding: '5.333333333vw'
      dots: true
    })
  
    $('.p-location__contents__text').find('a[href^="#"]').on('click'
      (e)->
        e.preventDefault()
  
        $('.p-doctors__slide.js-slide').slick('slickPause')
        $('.p-doctors__slide.js-slide').slick('slickGoTo', $($(this).attr('href')).closest('.p-doctors__slide__item').attr('data-slick-index'))
        setTimeout(
          ()->
            $('.p-doctors__slide.js-slide').slick('slickPlay')
        , 1000)
    )
  )
  
  $('.js-menu__button').on('click'
    ()->
      $(this).toggleClass('is-active')
      $menu.toggleClass('is-active')
      $('body').toggleClass('is-active')
  )
  
  $menu.find('.js-anchor').find('a').on('click',
    ()->
      $('.js-menu__button').toggleClass('is-active')
      $menu.toggleClass('is-active')
  )
  
  spMenu = ()->
    console.log('menu:SP')
  
    $(window).scroll(
      ()->
        if $(this).scrollTop() > $('.p-header').outerHeight()
          $('.js-fix-cv').addClass('is-fixed')
        else
          $('.js-fix-cv').removeClass('is-fixed')
    )
  
  pcMenu = ()->
    console.log('menu:PC')
  
  if window.matchMedia('(max-width:750px)').matches
    # SP
    firstView = 'SP'
  
    spMenu()
  else
    # PC
    firstView = 'PC'
  
    pcMenu()
  
  $(window).on('resize',
    ()->
      if window.matchMedia('(max-width:750px)').matches
        if firstView is 'PC' and resizeFlg.sp is true or
        firstView is false and resizeFlg.sp is true
        # PC から SP へ
  
          spMenu()
  
          resizeFlg.pc = true
          resizeFlg.sp = false
          firstView = false
      else
        if firstView is 'SP' and resizeFlg.pc is true or
        firstView is false and resizeFlg.pc is true
        # SP から PC へ
  
          pcMenu()
  
          resizeFlg.pc = false
          resizeFlg.sp = true
          firstView = false
  )
  